import { LOADER_ACTIONS } from './actions'

export const INITIAL_STATE = {

}

const reducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action
  switch (type) {
    case LOADER_ACTIONS.LOADER_UPDATE:
      return {
        ...state,
        ...payload
      }
    case LOADER_ACTIONS.RE_INITIATE_LOADER:
      return {
        ...INITIAL_STATE
      }
    default:
      return {
        ...state
      }
  }
}
export default reducer
