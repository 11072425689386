export const ERROR_ACTIONS = {
  ERROR_UPDATE: 'ERROR_UPDATE',
  STATE_RESET: 'STATE_RESET'
}

export const updateError = (payload) => ({
  type: ERROR_ACTIONS.ERROR_UPDATE,
  payload
})

export const resetError = (payload) => ({
  type: ERROR_ACTIONS.ERROR_UPDATE,
  payload
})

export const performErrorReset = (keys = []) => ({
  type: ERROR_ACTIONS.STATE_RESET,
  payload: keys
})
