// eslint-disable-next-line no-unused-vars
import React, { createContext, useContext } from 'react'

export const ServiceContext = createContext()

export const ServiceContextProvider = (props = {}) => {
  // props contains the children headers, getGlobalErrorMessages
  // and we are separating children and restProps
  // headers: {} : https headers
  // getGlobalErrorMessages: () => {} this method use to provide the error msg
  // that set by the provider and the platform use this service
  // eslint-disable-next-line react/prop-types
  const { children, ...restProps } = props
  return (
    <ServiceContext.Provider value={{
      headers: restProps.headers || {},
      getGlobalErrorMessages: restProps.getGlobalErrorMessages || (() => {})
    }}
    >
      {children}
    </ServiceContext.Provider>
  )
}

export const useServiceContextValue = () => useContext(ServiceContext)
