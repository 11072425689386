import { mapArrayElementsKeysToObjectKeys } from '../../helpers/utility'
import { API_ACTIONS } from './actions'

const reducer = (state, action) => {
  const { payload } = action
  if (action.isApiData && !action.hasCustomReducer) {
    return { ...state, ...payload }
  }
  switch (action.type) {
    case API_ACTIONS.RESET_STATE:
      return { ...state, ...mapArrayElementsKeysToObjectKeys(payload) }
    default:
      return state
  }
}

export default reducer
