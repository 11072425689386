import axios from 'axios'
import { queryBuilder, urlMapper } from '../helpers/utility'

const serviceCall = ({
  method, url = '', query = {}, params = {}, headers = {}, body = {}
}) => {
  const queryString = queryBuilder(query)
  const mappedUrl = urlMapper(url, params)
  const combinedUrl = `${mappedUrl}${queryString}`
  return axios({
    method,
    body,
    data: body,
    url: combinedUrl,
    headers: {
      'Content-Type': 'application/json',
      ...headers
    }
  }).catch(error => {
    if (error.response) {
      throw error.response
    } else {
      throw error
    }
  }).then(response => {
    return response
  })
}

export default serviceCall
