import { useCallback } from 'react'
import { useErrorContextValue } from '../../../context/ErrorContext'
import { performErrorReset } from '../actions'

export const useResetError = () => {
  const [, dispatch] = useErrorContextValue()

  const resetError = useCallback((keys = []) => {
    dispatch(performErrorReset(keys))
  }, [])
  return { resetError }
}
