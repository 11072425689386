export const LOADER_ACTIONS = {
  LOADER_UPDATE: 'LOADER_UPDATE',
  RE_INITIATE_LOADER: 'RE_INITIATE_LOADER'
}

export const updateLoader = (payload) => ({
  type: LOADER_ACTIONS.LOADER_UPDATE,
  payload
})

export const reInitiateLoader = () => ({
  type: LOADER_ACTIONS.RE_INITIATE_LOADER
})
