/* eslint-disable react/prop-types */
// eslint-disable-next-line no-unused-vars
import React, { createContext, useContext, useReducer } from 'react'
import { combineReducers } from '../../helpers/utility'
import rootReducer from '../../state/ApiState/reducers'

export const ApiContext = createContext()

export const ApiContextProvider = ({ reducer, children }) => {
  return (
    <ApiContext.Provider
      value={useReducer(combineReducers(reducer ? [reducer, rootReducer] : [rootReducer]), {})}
    >
      {children}
    </ApiContext.Provider>
  )
}

export const useApiContextValue = () => useContext(ApiContext)
