/* eslint-disable react/prop-types */
// eslint-disable-next-line no-unused-vars
import React, { createContext, useContext, useReducer } from 'react'
import reducer, { INITIAL_STATE } from '../../state/loaderState/reducers'

export const LoaderContext = createContext()

export const LoaderContextProvider = (props) => {
  return (
    <LoaderContext.Provider value={useReducer(reducer, INITIAL_STATE)}>
      {props.children}
    </LoaderContext.Provider>
  )
}

export const useLoaderContextValue = () => useContext(LoaderContext)
