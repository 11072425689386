export const API_ACTIONS = {
  RESET_STATE: 'RESET_STATE',
  resetState: keys => ({
    type: API_ACTIONS.RESET_STATE,
    payload: keys
  }),
  apiCall: ({ payload, url, type, method }) => ({
    type,
    payload: {
      ...payload,
      method,
      url
    }
  })
}
