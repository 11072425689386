import { useCallback, useEffect } from 'react'
import { useApiContextValue } from '../../../context/ApiContext'
import { camelCase } from '../../../helpers/utility'
import { useServiceRequest } from '../../../hooks/useServiceRequest'
import { useResetError } from '../../errorState/hooks/useResetError'
import { API_ACTIONS } from '../actions'
import { METHODS } from '../constants'

const methodDataKey = {
  [METHODS.GET]: 'query',
  [METHODS.POST]: 'body'
}

export const useResetState = () => {
  const [, dispatch] = useApiContextValue()
  const resetState = useCallback((keys = []) => {
    dispatch(API_ACTIONS.resetState(keys))
  }, [dispatch])
  return { resetState }
}

export const useApi = ({ dataKey, url, method, hasCustomReducer }) => {
  const camelCaseKey = camelCase(dataKey) + 'Data'
  const [{ [camelCaseKey]: apiData }, dispatch] = useApiContextValue()
  const { resetError } = useResetError()
  const [callApi] = useServiceRequest(dispatch, hasCustomReducer)
  const requestDataKey = `${dataKey}_REQUEST`

  useEffect(() => {
    const camelCaseErrorStatusKey = camelCase(dataKey) + 'Status'
    const ERROR_KEY = dataKey + '_ERROR'
    resetError([camelCaseErrorStatusKey, ERROR_KEY])
  }, [resetError])

  const request = useCallback((payload) => {
    callApi(API_ACTIONS.apiCall({
      payload: { [methodDataKey[method]]: payload },
      url,
      type: requestDataKey,
      method
    }))
  }, [callApi])
  return [apiData, request]
}

export const useGet = ({ dataKey, url, hasCustomReducer = false }) => {
  return useApi({ dataKey, url, method: METHODS.GET, hasCustomReducer })
}

export const usePost = ({ dataKey, url, hasCustomReducer = false }) => {
  return useApi({ dataKey, url, method: METHODS.POST, hasCustomReducer })
}
