import React, { createContext, useContext, useReducer } from 'react'
import reducer, { INITIAL_STATE } from '../../state/errorState/reducers'

export const ErrorContext = createContext()

export const ErrorContextProvider = (props) => {
  return (
    <ErrorContext.Provider value={useReducer(reducer, INITIAL_STATE)}>
      {props.children}
    </ErrorContext.Provider>
  )
}

export const useErrorContextValue = () => useContext(ErrorContext)
